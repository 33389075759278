import http from "./constants/http";
import resources from "./constants/resources";

// 투표
export const voteAPI = async (body) => {
  const res = await http(resources.VOTE).post(body);
  if (res.status === 200) {
    return res.data;
  } else {
    return res;
  }
};

// 공동구매
export const EventPagesAPI = async (body) => {
  const res = await http(resources.EVENTPAGES).post(body);
  if (res.status === 200) {
    return res.data;
  } else {
    return res;
  }
};
// 공동구매 파일
export const EventPagesFileAPI = async (body) => {
  const res = await http(resources.EVENTPAGES).postFiles(body);

  return res;
};

// 댓글관련
export const commentAPI = async (body) => {
  const res = await http(resources.COMMENT).post(body);
  if (res.status === 200) {
    return res.data;
  } else {
    return res;
  }
};
