import * as H from "../Header.styles";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Pagination]);

const StripeBanner = ({ banners }) => {
  return (
    <>
      <H.StripeBannerWrapper>
        {banners?.main_stripe && banners?.main_stripe.length > 0 && (
          <Swiper
            centeredSlides
            autoplay={true}
            freeMode={true}
            observer={true}
            observeParents={true}
            speed={300}
            allowTouchMove={false}
            loop={true}
            loopedSlides={4}
            breakpoints={{
              575: {
                slidesPerView: 2,
                speed: 8000,
                autoplay: {
                  delay: 0,
                  disableOnInteraction: false,
                  stopOnLastSlide: false,
                },
              },
              768: {
                slidesPerView: 2,
                speed: 8000,
                autoplay: {
                  delay: 0,
                  disableOnInteraction: false,
                  stopOnLastSlide: false,
                },
              },
              992: {
                slidesPerView: 3,
                speed: 8000,
                autoplay: {
                  delay: 0,
                  disableOnInteraction: false,
                  stopOnLastSlide: false,
                },
              },
              1440: {
                slidesPerView: 4,
                speed: 8000,
                autoplay: {
                  delay: 0,
                  disableOnInteraction: false,
                  stopOnLastSlide: false,
                },
              },
            }}
          >
            <>
              {banners.main_stripe.map((banner, i) => (
                <SwiperSlide key={i} className="swiper-slide">
                  <H.StripeBanner>
                    <a
                      href={banner.link_url}
                      target={banner.new_win === "Y" ? "_blank" : ""}
                    >
                      {banner.img_alt}
                    </a>
                  </H.StripeBanner>
                </SwiperSlide>
              ))}
            </>
          </Swiper>
        )}
      </H.StripeBannerWrapper>
    </>
  );
};
export default StripeBanner;
