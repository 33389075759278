import { Spinner } from "react-bootstrap";
import * as H from "../Header.styles";
import Link from "next/link";
import Search from "./search/Search";

const FirstHeader = ({
  logo,
  banners,
  awardIdx,
  scroll,
  openSearch,
  setOpenSearch,
  scrollSearchBtnRef,
}) => {
  return (
    <div className="container">
      <H.TopHeader>
        <H.LogoWrapper>
          {logo?.pc && logo.pc !== "" ? (
            <Link href="/">
              <a>
                <img src={logo.pc} alt="pc_logo" className="pc" />
                <img src={logo.over} alt="over_logo" className="over" />
              </a>
            </Link>
          ) : (
            <Spinner animation="border" role="status" />
          )}
        </H.LogoWrapper>
        <H.SearchWrapper>
          <Search
            scroll={scroll}
            openSearch={openSearch}
            setOpenSearch={setOpenSearch}
            scrollSearchBtnRef={scrollSearchBtnRef}
          />
        </H.SearchWrapper>
        <H.AwardWrapper>
          {banners?.main_award &&
            banners?.main_award.length > 0 &&
            banners?.main_award[awardIdx] &&
            banners?.main_award.map((img, imgIdx) => (
              <img
                key={imgIdx}
                src={img.P_FileUrl}
                alt="2023패키징대전"
                style={{ display: awardIdx === imgIdx ? "block" : "none" }}
              />
            ))}
        </H.AwardWrapper>
      </H.TopHeader>
    </div>
  );
};
export default FirstHeader;
