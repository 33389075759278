import styled, { css, keyframes } from "styled-components";

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

export const Container = styled.section`
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  left: 0;
  visibility: ${({ searchToggled }) => (searchToggled ? "visible" : "hidden")};
  opacity: ${({ searchToggled }) => (searchToggled ? 1 : 0)};
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  z-index: 10000050;
  ${({ searchToggled }) =>
    searchToggled
      ? css`
          animation: ${slideIn} 0.3s ease-in-out;
        `
      : css`
          animation: ${slideOut} 0.3s ease-in-out;
        `}
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0 0 50px 0;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #1b1b1b;
  padding: 15px;
  position: relative;

  .back-button {
    font-size: 20px;
    & i {
      display: flex;
    }
  }
`;

export const Body = styled.div`
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
`;

export const SeacrhSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const HeaderSearch = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  & input {
    width: 100%;
    max-height: 30px;
    border: none;
  }
`;

export const SeacrhTags = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  & h6 {
    margin-bottom: 20px;
  }
`;

export const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  & a {
    margin-right: 10px;
    margin-bottom: 10px;
    color: #fff !important;
    background-color: #3bb77e;
    padding: 5px 10px;
    border-radius: 10px;
  }
`;

export const RankWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & h6 {
    margin-bottom: 20px;
  }

  & li {
    border-bottom: 1px solid #ececec;
    padding-bottom: 10px;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  & span {
    color: #1b1b1b;
    font-size: 14px;
  }

  .rank {
    min-width: 20px;
    font-size: 16px;
    font-weight: 800;
    color: #3bb77e;
    margin-right: 5px;
    display: flex;
    justify-content: end;
    line-height: 26px;
  }

  .keyword {
    font-size: 14px;
    margin-right: 5px;
    line-height: 16.94px;
  }

  .desc {
    color: #acacac;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.73px;
    padding-top: 1px;
  }
`;

export const FilteredTagsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: calc(100% + 1px);
  z-index: 999999;
  background-color: #fff;
  width: 100%;
  padding: 0px 30px 20px;
  border-bottom: 1px solid #ececec;
`;

export const MobileSizeSearchWrapper = styled.div`
  padding: 10px 0px 20px;
  display: flex;
  flex-direction: column;

  .size_input_wrapper {
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
  }
  .btn {
    font-size: 16px;
    font-weight: 400;
    padding: 5px 10px;
  }
`;

export const SizeInputBox = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  padding: 5px 40px 5px 5px;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  & input {
    border: none;
    border-radius: 0%;
    height: 100%;
    padding: 0px 10px;
    color: #253d4e;
    font-size: 16px;
    width: calc(100% - 40px);
    background-color: transparent;
  }
  ::after {
    content: "mm";
    position: absolute;
    top: 0px;
    right: 0px;
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #253d4e;
    font-size: 16px;
  }
`;

export const SizeRangeSelect = styled.label`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 0px;

  & select {
    width: 100%;
    padding: 5px 16px;
    cursor: pointer;
  }
  ::after {
    font-family: uicons-regular-straight !important;
    font-weight: normal !important;
    content: "\f131";
    position: absolute;
    right: 10px;
    top: 0px;
    width: 16px;
    font-size: 16px;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: -1;
  }
`;
