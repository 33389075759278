import checkSiteConfig from "../../api/checkSiteConfig";
import * as Types from "../constants/actionTypes";

export const fetchSiteInfo = (router) => async (dispatch) => {
  try {
    const body = {
      modes: "Config",
      division: "store",
      channel:
        router.query.channel ||
        (sessionStorage.getItem("channelName") === "바로고" ? "BAROGO" : ""),
    };
    const res = await checkSiteConfig(body);
    if (res?.Code === "0000") {
      if (res.Data.channel.token) {
        sessionStorage.setItem("channel", res.Data.channel.token);
        sessionStorage.setItem("channelName", res.Data.channel.company);
        sessionStorage.setItem(
          "channelInfo",
          `${res.Data.channel.company ? res.Data.channel.company + "+" : ""}${
            res.Data.channel.regIdYn
          }`
        );
      }
      sessionStorage.setItem(
        "logo",
        JSON.stringify({
          pc: res.Data.channel.logo,
          mobile: res.Data.channel.mobile_logo,
        })
      );
    }
    dispatch({
      type: Types.SITE_INFO,
      payload: { siteInfo: res.Data },
    });
  } catch (error) {
    console.error(error);
  }
};
