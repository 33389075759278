import * as Types from "../constants/actionTypes";

export const setAllianceInfo = (allianceInfo) => (dispatch) => {
  dispatch({
    type: Types.ALLIANCE_INFO,
    payload: {
      allianceInfo,
    },
  });
};
