import {
  clearBaskets,
  deleteBasket,
  fetchBaskets,
  insertBaskets,
} from "../../api/baskets";
import { successAlert } from "../../common/alert";
import * as Types from "../constants/actionTypes";

// 회원 장바구니 리스트
export const fetchBasketsAction = () => async (dispatch) => {
  try {
    const body = {
      modes: "ListSet",
    };
    const data = await fetchBaskets(body);
    dispatch({
      type: Types.FETCH_BASKETS,
      payload: {
        baskets: data?.data?.filter((el) => el.pcode != null),
        msg: data?.msg,
        count: data?.data?.filter((el) => el.pcode != null)?.length || 0,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

// 회원 장바구니 등록
export const insertBasketsAction = (product, cnt) => async (dispatch) => {
  try {
    const body = {
      modes: "InsertSet",
      items: JSON.stringify([{ ...product, pcode: product.pcode, cnt: cnt }]),
    };
    const data = await insertBaskets(body);
    dispatch({
      type: Types.INSERT_BASKETS,
      payload: {
        code: data?.code,
        baskets: data?.data,
        count: data?.count,
        msg: data?.msg,
        product,
      },
    });
  } catch (error) {
    console.error(error.message);
  }
};

// 회원 장바구니 삭제
export const deleteBasketsAction = (product) => async (dispatch) => {
  try {
    const body = {
      modes: "DeleteSet",
      pcode: product.pcode,
    };
    const res = await deleteBasket(body);

    const body2 = {
      modes: "ListSet",
    };
    const data = await fetchBaskets(body2);

    dispatch({
      type: Types.DELETE_BASKETS,
      payload: {
        msg: data?.msg,
        product,
        baskets: data?.data,
        count: data?.count,
      },
    });
  } catch (error) {
    console.error(error.message);
  }
};

// 장바구니 비우기
export const clearBasketsAction = () => async (dispatch) => {
  try {
    const body = {
      modes: "EmptySet",
    };
    const res = await clearBaskets(body);

    if (res.code === "0000") successAlert("장바구니를 비웠습니다");

    const body2 = {
      modes: "ListSet",
    };
    const data = await fetchBaskets(body2);

    dispatch({
      type: Types.CLEAR_BASKETS,
      payload: {
        msg: data?.msg,
        baskets: data?.data,
        count: data?.count,
      },
    });
  } catch (error) {
    console.error(error.message);
  }
};

// 리덕스 초기화
export const initBaskets = () => (dispatch) => {
  dispatch({
    type: Types.INIT_BASKETS,
  });
};
