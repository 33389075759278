import React from "react";
import Link from "next/link";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { modifyModal, openModal } from "../../redux/action/modal";
import * as F from "./Footer.styles";
import FooterFloating from "../floating/footer";

const Footer = ({ openModal, modifyModal, dummyList }) => {
  const [scrollY, setScrollY] = useState();

  const onClickTopButton = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY > 0;
      if (scrollCheck !== scroll) {
        setScrollY(scrollCheck);
      }
    });
  });

  return (
    <>
      <footer className="main">
        <FooterFloating onClickTopButton={onClickTopButton} scrollY={scrollY} />
        {/* pc */}
        <F.FooterBodySection className="section-padding pt-0 footer-mid">
          <div className="container pb-20">
            <div className="footer-bottom mb-40 row" />
            <F.FooterBottomWrapper>
              <F.FooterBottomCol>
                <Link href="/">
                  <a>
                    <img
                      src="/assets/imgs/theme/logo-footer-icon.svg"
                      alt="logo"
                      style={{ width: "15rem" }}
                    />
                  </a>
                </Link>
                <p className="font-lg text-heading">
                  스마트 패키징 솔루션 플랫폼 <br />
                  칼렛스토어는 스마트 그린테크를 통해
                  <br /> 지속가능한 패키징 솔루션을 <br />
                  제안하는 플랫폼입니다.
                </p>
              </F.FooterBottomCol>
              <F.FooterBottomCol>
                <h4 className="widget-title ">INTRODUCTION</h4>
                <div
                  className="widget-title-underLine"
                  style={{
                    width: "3rem",
                    borderTop: "2px solid #3bb77e",
                    marginBottom: "10px",
                  }}
                />
                <ul className="contact-infor">
                  <li>
                    <img
                      src="/assets/imgs/theme/icons/icon-location.svg"
                      alt=""
                    />
                    <h6>주식회사칼렛바이오 대표 :</h6>
                    <span>권영삼</span>
                  </li>
                  <li>
                    <img
                      src="/assets/imgs/theme/icons/icon-location.svg"
                      alt=""
                    />
                    <h6>사업자 등록번호 :</h6>
                    <span>543-81-01280</span>
                    <a
                      href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=5438101280"
                      style={{
                        width: "fit-content",
                        color: "#253D4E",
                      }}
                      target="_blank"
                    >
                      [사업자등록정보확인]
                    </a>
                  </li>
                  <li>
                    <img
                      src="/assets/imgs/theme/icons/icon-location.svg"
                      alt=""
                    />
                    <h6>주소 : </h6>
                    <span>
                      서울특별시 마포구 백범로 235 2층 <br />
                      (신공덕동, 신보빌딩)
                    </span>
                  </li>
                  <li>
                    <img
                      src="/assets/imgs/theme/icons/icon-location.svg"
                      alt=""
                    />
                    <h6>통신판매업 : </h6>
                    <span>제2022-서울마포-2371호</span>
                  </li>
                  <li>
                    <img
                      src="/assets/imgs/theme/icons/icon-contact.svg"
                      alt=""
                    />
                    <h6>TEL : </h6>

                    <span>02-6964-6930 </span>
                    <h6>FAX : </h6>
                    <span>02-6455-5692</span>
                  </li>
                  <li>
                    <img src="/assets/imgs/theme/icons/icon-clock.svg" alt="" />
                    <h6>상담가능시간 : </h6>
                    <span>09:00 - 18:00, 월 - 금</span>
                  </li>
                  <li>
                    <img
                      src="/assets/imgs/theme/icons/icon-email-2.svg"
                      alt=""
                    />
                    <h6>제휴문의 : </h6>
                    <span>
                      <a href="/csboard/contact">제휴 문의 하러가기</a>
                    </span>
                  </li>
                </ul>
              </F.FooterBottomCol>
              <F.FooterBottomCol>
                <h4 className="widget-title">COMMUNITY</h4>
                <div
                  className="widget-title-underLine"
                  style={{
                    width: "3rem",
                    borderTop: "2px solid #3bb77e",
                    marginBottom: "10px",
                  }}
                />
                <ul className="footer-list  mb-sm-5 mb-md-0">
                  <li>
                    <a href="/about">회사소개</a>
                  </li>
                  <li>
                    <a href="/csboard/notice/">공지사항</a>
                  </li>
                  <li>
                    <a href="/csboard/faq/">FAQ</a>
                  </li>
                  <li>
                    <a href="/privacy-policy">개인정보처리방침</a>
                  </li>
                  <li>
                    <a href="/store-policy">이용약관</a>
                  </li>
                  <li>
                    <a href="http://www.stepfornetzero.com/" target="_blank">
                      스탭포넷제로
                    </a>
                  </li>
                  <F.MobileSocialIconWrapper>
                    <Link href="https://pf.kakao.com/_MCxnhxj" target="_blank">
                      <a target="_blank">
                        <img
                          src="/assets/imgs/theme/icons/kakao-talk.png"
                          alt=""
                        />
                      </a>
                    </Link>
                    <Link
                      href="https://www.facebook.com/caretstore"
                      target="_blank"
                    >
                      <a target="_blank">
                        <img
                          src="/assets/imgs/theme/icons/icon-facebook-white.svg"
                          alt=""
                        />
                      </a>
                    </Link>
                    <Link
                      href="https://www.instagram.com/caretstore/"
                      target="_blank"
                    >
                      <a target="_blank">
                        <img
                          src="/assets/imgs/theme/icons/icon-instagram-white.svg"
                          alt=""
                        />
                      </a>
                    </Link>
                    <Link href="https://talk.naver.com/ct/w4kznv">
                      <a target="_blank">
                        <img
                          src="/assets/imgs/theme/icons/icon-naver-talk.png"
                          alt=""
                        />
                      </a>
                    </Link>
                    <Link href="https://www.youtube.com/channel/UC1MTYtvC363yWAB4uf8CBhQ">
                      <a target="_blank">
                        <img
                          src="/assets/imgs/theme/icons/icon-youtube-white.svg"
                          alt=""
                        />
                      </a>
                    </Link>
                    <Link
                      href="https://blog.naver.com/caretstore"
                      target="_blank"
                    >
                      <a target="_blank">
                        <img
                          src="https://cdn.imweb.me/upload/S20200506960caff1c89c9/522104c187709.png"
                          alt=""
                        />
                      </a>
                    </Link>
                  </F.MobileSocialIconWrapper>
                </ul>
              </F.FooterBottomCol>
            </F.FooterBottomWrapper>
          </div>
        </F.FooterBodySection>
        {/* mobile */}
        <F.MobileFooterBodySection className="section-padding pt-0 footer-mid">
          <div className="container pb-20">
            <div className="footer-bottom mb-20 row" />
            <F.MobileListWrapper className="">
              <li>
                <a href="/about">회사소개</a>
              </li>
              <li>
                <a href="/store-policy">이용약관</a>
              </li>
              <li>
                <a href="/privacy-policy">개인정보처리방침</a>
              </li>
              <li>
                <a href="/csboard/notice/">공지사항</a>
              </li>
              <li>
                <a href="/csboard/faq/">FAQ</a>
              </li>
              <li>
                <a href="http://www.stepfornetzero.com/" target="_blank">
                  스탭포넷제로
                </a>
              </li>
            </F.MobileListWrapper>
            <F.MobileContactInfo>
              <li>
                <h6>주식회사칼렛바이오 대표 :</h6>
                <span>권영삼</span>
              </li>
              <li>
                <h6>사업자 등록번호 :</h6>
                <span>543-81-01280</span>
              </li>
              <li>
                <h6>통신판매업 : </h6>
                <span>제2022-서울마포-2371호</span>
              </li>
              <li>
                <h6>주소 : </h6>
                <span>
                  서울특별시 마포구 백범로 235 2층 (신공덕동, 신보빌딩)
                </span>
              </li>
            </F.MobileContactInfo>
            <F.MobileContactInfo>
              <li>
                <h6>TEL : </h6>
                <span>02-6964-6930 </span>
              </li>
              <li>
                <h6>FAX : </h6>
                <span>02-6455-5692</span>
              </li>
              <li>
                <h6>상담가능시간 : </h6>
                <span>09:00 - 18:00, 월 - 금</span>
              </li>
              <li>
                <h6>제휴문의 : </h6>
                <span>
                  <a href="/csboard/contact">제휴 문의 하러가기</a>
                </span>
              </li>
            </F.MobileContactInfo>
            <F.MobileSocialIconWrapper>
              <Link href="https://pf.kakao.com/_MCxnhxj" target="_blank">
                <a target="_blank">
                  <img src="/assets/imgs/theme/icons/kakao-talk.png" alt="" />
                </a>
              </Link>
              <Link href="https://www.facebook.com/caretstore" target="_blank">
                <a target="_blank">
                  <img
                    src="/assets/imgs/theme/icons/icon-facebook-white.svg"
                    alt=""
                  />
                </a>
              </Link>
              <Link
                href="https://www.instagram.com/caretstore/"
                target="_blank"
              >
                <a target="_blank">
                  <img
                    src="/assets/imgs/theme/icons/icon-instagram-white.svg"
                    alt=""
                  />
                </a>
              </Link>
              <Link href="https://talk.naver.com/ct/w4kznv">
                <a target="_blank">
                  <img
                    src="/assets/imgs/theme/icons/icon-naver-talk.png"
                    alt=""
                  />
                </a>
              </Link>
              <Link href="https://www.youtube.com/channel/UC1MTYtvC363yWAB4uf8CBhQ">
                <a target="_blank">
                  <img
                    src="/assets/imgs/theme/icons/icon-youtube-white.svg"
                    alt=""
                  />
                </a>
              </Link>
              <Link href="https://blog.naver.com/caretstore" target="_blank">
                <a target="_blank">
                  <img
                    src="https://cdn.imweb.me/upload/S20200506960caff1c89c9/522104c187709.png"
                    alt=""
                  />
                </a>
              </Link>
            </F.MobileSocialIconWrapper>
          </div>
        </F.MobileFooterBodySection>
        <F.FooterCopyWrapper
          className="container pb-20  wow animate__animated animate__fadeInUp "
          data-wow-delay="0"
        >
          <div className="row align-items-center justify-content-center">
            <div className="footer-bottom row"></div>
            <div
              className="col-xl-5 col-lg-6 col-md-6 mt-10"
              style={{ textAlign: "center" }}
            >
              <p className="font-sm mb-0">
                &copy; 2021 CARETBIO. All rights reserved
              </p>
            </div>
          </div>
        </F.FooterCopyWrapper>
      </footer>
    </>
  );
};

const mapStateToProps = (state) => ({
  dummyList: state.dummyList,
});

const mapDispatchToProps = {
  openModal,
  modifyModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
