import { getAllUserInfo } from "../../api/getUserInfo";
import { failAlert } from "../../common/alert";
import { removeCookie } from "../../common/auth";
import * as Types from "../constants/actionTypes";

export const fetchUserInfo = () => async (dispatch) => {
  try {
    const res = await getAllUserInfo();

    if (res?.Code === "8888") {
      removeCookie("token");
      failAlert("로그인 유지 시간이 만료 되었습니다.");
      window.open("/login", "_self");
      return;
    } else if (res?.Code === "9999") {
      removeCookie("token");
      return window.open("/", "_self");
    } else {
      // gtag
      if (typeof dataLayer != undefined) {
        dataLayer.push({
          user_id: res?.Data?.uid,
        });
      }
      dispatch({
        type: Types.FETCH_USER_INFO,
        payload: { userInfo: { ...res?.Data } },
      });
      return "complete";
    }
  } catch (error) {
    console.log(error);

    return error;
  }
};

export const updateUserInfo = (info) => async (dispatch) => {
  dispatch({
    type: Types.UPDATE_USER_INFO,
    payload: { updateUserInfo: info },
  });
};

export const initUserInfo = () => (dispatch) => {
  dispatch({
    type: Types.INIT_USER_INFO,
    payload: {},
  });
};
