import Link from "next/link";
import * as M from "./MobileSearch.styles";
import { useEffect, useRef, useState } from "react";
import { failAlert } from "../../../common/alert";
import { useRouter } from "next/router";
import { postDefaultArray } from "../../../api/dummy";
import * as S from "../header/search/Search.styles";
import Image from "next/image";

const MobileSearch = ({ searchToggled, onClickSearch, dummyList }) => {
  const router = useRouter();
  const [searchTerm, setSearchTerm] = useState("");
  const [recentList, setRecentList] = useState([]);
  const [searchTags, setSearchTags] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [selectedSizes, setSizes] = useState({
    garo: "",
    sero: "",
    high: "",
    erange: "",
  });
  const onChangeSize = (e, id) => {
    setSizes({ ...selectedSizes, [id]: e.target.value });
  };

  const handleSearch = (txt) => {
    if (
      !txt &&
      Object.values(selectedSizes)?.filter((el) => el > 0)?.length == 0
    ) {
      return failAlert("검색어 혹은 사이즈를 입력해주세요.");
    }
    if (txt) {
      if (txt.length < 2) return failAlert("두 글자 이상 입력해주세요.");
      clearTimeout();

      if (recentList.includes(txt)) {
        const idx = recentList.findIndex((v) => v === txt);
        recentList.splice(idx, 1);
        setRecentList([txt, ...recentList]);
        localStorage.setItem("RST", JSON.stringify([txt, ...recentList]));
      } else {
        if (recentList.length === 9) {
          const tempArr = recentList.slice(0, 8);
          setRecentList([txt, ...tempArr]);
          localStorage.setItem("RST", JSON.stringify([txt, ...tempArr]));
        } else {
          setRecentList([txt, ...recentList]);
          localStorage.setItem("RST", JSON.stringify([txt, ...recentList]));
        }
      }
    }

    router.push({
      pathname: "/search",
      query: {
        ...selectedSizes,
        search: txt,
      },
    });
    setOpenSearch(false);
    setSearchTerm("");
  };

  const handleInput = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setTimeout(() => {
        handleSearch(searchTerm);
      }, 200);
    }
  };

  const deleteRecentSearch = (i) => {
    recentList.splice(i, 1);
    setRecentList([...recentList]);
    localStorage.setItem("RST", JSON.stringify([...recentList]));
  };

  const deleteRecentSearchAll = () => {
    setRecentList([]);
    localStorage.setItem("RST", JSON.stringify([]));
  };

  // 검색어 불러오기
  const fetchSearchTag = async () => {
    const body = {
      modes: "AllSearchTag",
    };
    const res = await postDefaultArray(body);
    if (res?.Data?.Data) {
      setSearchTags(res.Data?.Data);
    }
  };

  useEffect(() => {
    if (!router.isReady) return;
    fetchSearchTag();
    if (searchToggled === true) {
      onClickSearch();
    }
    if (router.query.search) {
      setSearchTerm(router.query.search);
    }
    if (Object.values(router.query)?.filter((el) => el > 0)?.length > 0) {
      setSizes({
        garo: router.query?.garo > 0 ? router.query.garo : "",
        sero: router.query?.sero > 0 ? router.query.sero : "",
        high: router.query?.high > 0 ? router.query.high : "",
        erange: router.query?.erange > 0 ? router.query.erange : "",
      });
    }
    setRecentList(JSON.parse(localStorage.getItem("RST")) || []);
  }, [router.isReady, router.query]);

  useEffect(() => {
    if (searchTags?.length === 0) return;
    if (searchTerm?.length > 0) {
      setFilteredTags(
        searchTags
          .filter((el) => el.toLowerCase().includes(searchTerm.toLowerCase()))
          .slice(0, 10)
      );
    } else {
      setFilteredTags([]);
    }
  }, [searchTerm]);

  const sizes = [0, 10, 20, 30, 40, 50, 60];

  return (
    <>
      <M.Container searchToggled={searchToggled}>
        <M.InnerWrapper>
          <M.Header>
            <a role="button" className="back-button">
              <i className="fi-rs-arrow-left" onClick={onClickSearch} />
            </a>
            <M.HeaderSearch>
              <input
                value={searchTerm}
                onKeyDown={handleInput}
                onChange={(e) => setSearchTerm(e.target.value)}
                type="text"
                placeholder="검색어를 입력해주세요"
                onFocus={(e) => setOpenSearch(true)}
              />
            </M.HeaderSearch>
            {openSearch && (
              <>
                <M.FilteredTagsWrapper>
                  <M.MobileSizeSearchWrapper>
                    <div className="size_input_wrapper">
                      <M.SizeInputBox>
                        <input
                          placeholder="가로"
                          type="tel"
                          value={selectedSizes?.garo}
                          onChange={(e) => onChangeSize(e, "garo")}
                          onKeyDown={handleInput}
                        />
                      </M.SizeInputBox>
                      <M.SizeInputBox>
                        <input
                          placeholder="세로"
                          type="tel"
                          value={selectedSizes?.sero}
                          onChange={(e) => onChangeSize(e, "sero")}
                          onKeyDown={handleInput}
                        />
                      </M.SizeInputBox>
                      <M.SizeInputBox>
                        <input
                          placeholder="높이"
                          type="tel"
                          value={selectedSizes?.high}
                          onChange={(e) => onChangeSize(e, "high")}
                          onKeyDown={handleInput}
                        />
                      </M.SizeInputBox>
                      <M.SizeRangeSelect htmlFor="size-erange">
                        <select
                          id="size-erange"
                          value={selectedSizes?.erange}
                          onChange={(e) => onChangeSize(e, "erange")}
                        >
                          {sizes.map((el, i) => (
                            <option value={el > 0 ? el : ""} key={i}>
                              ± {el}
                            </option>
                          ))}
                        </select>
                      </M.SizeRangeSelect>
                    </div>
                    <button
                      className="btn"
                      onClick={() => handleSearch(searchTerm)}
                    >
                      검색
                    </button>
                  </M.MobileSizeSearchWrapper>
                  <h6>
                    {filteredTags.length > 0 ? "연관 검색어" : "최근 검색어"}
                  </h6>
                  <S.RecentList>
                    {filteredTags.length > 0 ? (
                      filteredTags.map((el, i) => (
                        <S.RecentRow key={i}>
                          <figure>
                            <Image
                              src="/icons/search_icon_gray.png"
                              width={18}
                              height={18}
                              priority
                              alt={"search_icon"}
                            />
                          </figure>
                          <a
                            onClick={() => {
                              setSizes({
                                garo: "",
                                sero: "",
                                high: "",
                                erange: "",
                              });
                              setOpenSearch(false);
                              router.push(`/search/?search=${el}`);
                            }}
                            role="button"
                          >
                            {el}
                          </a>
                        </S.RecentRow>
                      ))
                    ) : recentList.length > 0 ? (
                      recentList.map((el, i) => (
                        <S.RecentRow key={i}>
                          <figure>
                            <Image
                              src="/icons/search_icon_gray.png"
                              width={18}
                              height={18}
                              priority
                              alt={"search_icon"}
                            />
                          </figure>
                          <a
                            onClick={() => {
                              setSizes({
                                garo: "",
                                sero: "",
                                high: "",
                                erange: "",
                              });
                              setOpenSearch(false);
                              router.push(`/search/?search=${el}`);
                            }}
                            role="button"
                          >
                            {el}
                          </a>
                          <span
                            className="delete"
                            onClick={() => deleteRecentSearch(i)}
                          >
                            <i className="fi-rs-x" />
                          </span>
                        </S.RecentRow>
                      ))
                    ) : (
                      <div>최근 검색내역이 없습니다.</div>
                    )}
                    <div className="d-flex align-items-center justify-content-between mt-10">
                      {filteredTags?.length === 0 && recentList.length > 0 && (
                        <S.RecentDeleteAll
                          onClick={() => deleteRecentSearchAll()}
                        >
                          전체삭제
                        </S.RecentDeleteAll>
                      )}
                      <a
                        role="button"
                        className="ms-auto text-secondary"
                        onClick={() => setOpenSearch(false)}
                      >
                        닫기
                      </a>
                    </div>
                  </S.RecentList>
                </M.FilteredTagsWrapper>
              </>
            )}
          </M.Header>
          <M.Body>
            <M.SeacrhSection>
              <M.SeacrhTags>
                <h6>추천 검색어</h6>
                <M.TagList>
                  {dummyList?.keywords &&
                    Object.values(dummyList.keywords).map((el, i) => (
                      <a
                        onClick={() => {
                          setSizes({
                            garo: "",
                            sero: "",
                            high: "",
                            erange: "",
                          });
                          setOpenSearch(false);
                          router.push(`/search/?search=${el}`);
                        }}
                        role="button"
                        key={i}
                      >
                        {el}
                      </a>
                    ))}
                </M.TagList>
              </M.SeacrhTags>
              <M.RankWrapper>
                <h6>실시간 검색어</h6>
                <ul>
                  {dummyList?.ranking?.length > 0 &&
                    dummyList?.ranking.map((el, i) => (
                      <li key={i}>
                        <span className="rank">{i + 1}</span>
                        <a>
                          <span
                            className="keyword"
                            onClick={() => {
                              setSizes({
                                garo: "",
                                sero: "",
                                high: "",
                                erange: "",
                              });
                              setOpenSearch(false);
                              router.push(`/search/?search=${el.keyword}`);
                            }}
                            role="button"
                          >
                            {el.keyword}
                          </span>
                        </a>
                        <span className="desc">{el.content}</span>
                      </li>
                    ))}
                </ul>
              </M.RankWrapper>
            </M.SeacrhSection>
          </M.Body>
        </M.InnerWrapper>
      </M.Container>
    </>
  );
};
export default MobileSearch;
