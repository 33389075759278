import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { updateProductCategory } from "../../redux/action/productFiltersAction";

const CategorySection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CategorySelect = styled.div`
  margin: 10px 0px 0px 0px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 15px;
  & button {
    padding: 5px 5px;
    border: 1px solid #253d4e;
    background-color: #fff;
  }
  .S {
    border-color: ${(props) =>
      props.categoryType === "S" ? "#3bb77e" : "#7e7e7e"} !important;
    color: ${(props) =>
      props.categoryType === "S" ? "#ffffff" : "#7e7e7e"} !important;
    background-color: ${(props) =>
      props.categoryType === "S" ? "#3bb77e" : "#ffffff"} !important;
  }
  .T {
    border-color: ${(props) =>
      props.categoryType === "T" ? "#3bb77e" : "#7e7e7e"} !important;
    color: ${(props) =>
      props.categoryType === "T" ? "#ffffff" : "#7e7e7e"} !important;
    background-color: ${(props) =>
      props.categoryType === "T" ? "#3bb77e" : "#ffffff"} !important;
  }
`;

const CategoryListWrapper = styled.div`
  display: flex;
  min-width: 400px;
  width: fit-content;
`;

const CategoryList = styled.ul`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
`;

const Category = styled.li`
  width: 100%;
  cursor: pointer;
  margin: 0px !important;
`;

const HeaderCategory = ({
  setToggled,
  categoriRef,
  dummyList,
  updateProductCategory,
}) => {
  const router = useRouter();
  const [category, setCategory] = useState([]);
  const [categoryType, setCategoryType] = useState("S");

  const selectCategory = (e, category) => {
    if (category.code === router.query.code) return;
    e.preventDefault();
    router.push({
      pathname: "/products",
      query: {
        code: category.code,
        type: categoryType,
      },
    });
    categoriRef.current.children[1].style.display = "none";
    categoriRef.current.children[1].style.zIndex = -1;
  };

  const handleCategoryType = (type) => {
    setCategoryType(type);
    fetchCategory(type);
  };

  const fetchCategory = async () => {
    updateProductCategory(
      dummyList.category?.filter((el) =>
        categoryType === "T" ? el.division === "T" : el.division === "S"
      )
    );
    setCategory(
      dummyList.category?.filter((el) =>
        categoryType === "T" ? el.division === "T" : el.division === "S"
      )
    );
  };

  useEffect(() => {
    if (dummyList.category) {
      fetchCategory();
    }
  }, [dummyList, categoryType]);

  return (
    <CategorySection>
      <CategorySelect categoryType={categoryType}>
        <button
          className="S"
          value={"S"}
          onClick={(e) => handleCategoryType("S")}
        >
          포장재 먼저 선택
        </button>
        <button
          className="T"
          value={"T"}
          onClick={(e) => handleCategoryType("T")}
        >
          포장할 제품 먼저 선택
        </button>
      </CategorySelect>
      <CategoryListWrapper>
        <CategoryList>
          {category?.map((item, i) => (
            <Category onClick={(e) => selectCategory(e, item)} key={i}>
              <a>
                <img src={item.image} alt="" />
                {item.name}
              </a>
            </Category>
          ))}
        </CategoryList>
      </CategoryListWrapper>
    </CategorySection>
  );
};
const mapStateToProps = (state) => ({
  dummyList: state.dummyList,
});

const mapDidpatchToProps = {
  updateProductCategory,
};

export default connect(mapStateToProps, mapDidpatchToProps)(HeaderCategory);
