import * as Types from "../constants/actionTypes";

export const addToCart = (product, cnt) => (dispatch) => {
  dispatch({
    type: Types.ADD_TO_CART,
    payload: { product, cnt },
  });
};

export const deleteFromCart = (productCode) => (dispatch) => {
  dispatch({
    type: Types.DELETE_FROM_CART,
    payload: { productCode },
  });
};

export const setCartCnt = (product, cnt) => (dispatch) => {
  dispatch({
    type: Types.SET_CNT,
    payload: { product, cnt },
  });
};

export const increaseCnt = (productCode) => (dispatch) => {
  dispatch({
    type: Types.INCREASE_CNT,
    payload: { productCode },
  });
};

export const decreaseCnt = (productCode) => (dispatch) => {
  dispatch({
    type: Types.DECREASE_CNT,
    payload: { productCode },
  });
};

export const clearCart = () => (dispatch) => {
  dispatch({
    type: Types.CLEAR_CART,
  });
};
