import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { failAlert } from "../../../../common/alert";
import { isLoaded, isLoading } from "../../../../redux/action/loading";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import Link from "next/link";
import * as S from "./Search.styles";
import Image from "next/image";
import { SearchIcon } from "../../Header.styles";
import { postDefaultArray } from "../../../../api/dummy";
import _ from "lodash";
SwiperCore.use([Navigation, Pagination]);

const Search = ({
  scroll,
  isLoaded,
  isLoading,
  dummyList,
  openSearch,
  setOpenSearch,
  scrollSearchBtnRef,
}) => {
  const router = useRouter();
  const [searchTerm, setSearchTerm] = useState("");
  const [recentList, setRecentList] = useState([]);
  const floatingRef = useRef(null);
  const inputRef = useRef(null);
  const [searchTags, setSearchTags] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [selectedSizes, setSizes] = useState({
    garo: "",
    sero: "",
    high: "",
    erange: "",
  });
  const onChangeSize = (e, id) => {
    setSizes({ ...selectedSizes, [id]: e.target.value });
  };

  const handleSearch = (txt) => {
    if (
      !txt &&
      Object.values(selectedSizes)?.filter((el) => el > 0)?.length == 0
    ) {
      return failAlert("검색어 혹은 사이즈를 입력해주세요.");
    }
    if (txt) {
      if (txt.length < 2) return failAlert("두 글자 이상 입력해주세요.");
      clearTimeout();

      if (recentList.includes(txt)) {
        const idx = recentList.findIndex((v) => v === txt);
        recentList.splice(idx, 1);
        setRecentList([txt, ...recentList]);
        localStorage.setItem("RST", JSON.stringify([txt, ...recentList]));
      } else {
        if (recentList.length === 9) {
          const tempArr = recentList.slice(0, 8);
          setRecentList([txt, ...tempArr]);
          localStorage.setItem("RST", JSON.stringify([txt, ...tempArr]));
        } else {
          setRecentList([txt, ...recentList]);
          localStorage.setItem("RST", JSON.stringify([txt, ...recentList]));
        }
      }
    }

    router.push({
      pathname: "/search",
      query: {
        ...selectedSizes,
        search: txt,
      },
    });
    setOpenSearch(false);
    setSearchTerm("");
  };

  const deleteRecentSearch = (i) => {
    recentList.splice(i, 1);
    setRecentList([...recentList]);
    localStorage.setItem("RST", JSON.stringify([...recentList]));
  };

  const deleteRecentSearchAll = () => {
    setRecentList([]);
    localStorage.setItem("RST", JSON.stringify([]));
  };

  const handleInput = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setTimeout(() => {
        handleSearch(searchTerm);
      }, 200);
    }
  };

  // 검색어 불러오기
  const fetchSearchTag = async () => {
    const body = {
      modes: "AllSearchTag",
    };
    const res = await postDefaultArray(body);
    if (res?.Data?.Data) {
      setSearchTags(res.Data?.Data);
    }
  };

  useEffect(() => {
    if (!router.isReady) return;
    fetchSearchTag();
    if (router.query.search) {
      setSearchTerm(router.query.search);
    }
    if (Object.values(router.query)?.filter((el) => el > 0)?.length > 0) {
      setSizes({
        garo: router.query?.garo > 0 ? router.query.garo : "",
        sero: router.query?.sero > 0 ? router.query.sero : "",
        high: router.query?.high > 0 ? router.query.high : "",
        erange: router.query?.erange > 0 ? router.query.erange : "",
      });
    }
    setRecentList(JSON.parse(localStorage.getItem("RST")) || []);
  }, [router.isReady, router.query]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        inputRef.current.contains(e.target) ||
        floatingRef.current.contains(e.target) ||
        (scrollSearchBtnRef?.current &&
          scrollSearchBtnRef.current.contains(e.target))
      ) {
        return;
      } else {
        setOpenSearch(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchTags?.length === 0) return;
    if (searchTerm?.length > 0) {
      setFilteredTags(
        searchTags
          .filter((el) => el.toLowerCase().includes(searchTerm.toLowerCase()))
          .slice(0, 10)
      );
    } else {
      setFilteredTags([]);
    }
  }, [searchTerm]);

  const sizes = [0, 10, 20, 30, 40, 50, 60];

  return (
    <div className="d-flex position-relative w-100">
      <S.SeacrhSection
        className={`${scroll ? "d-none" : "d-flex"}`}
        ref={inputRef}
      >
        <S.SearchWrapper>
          <S.HeaderSearch>
            <input
              value={searchTerm}
              onKeyDown={handleInput}
              onChange={(e) => setSearchTerm(e.target.value)}
              type="text"
              onFocus={(e) => setOpenSearch(true)}
              placeholder="검색어를 입력하세요"
            />
          </S.HeaderSearch>
          <S.SizeSearchWrapper>
            <S.SizeInputBox>
              <input
                placeholder="가로"
                type="tel"
                value={selectedSizes?.garo}
                onChange={(e) => onChangeSize(e, "garo")}
                onKeyDown={handleInput}
              />
            </S.SizeInputBox>
            <S.SizeInputBox>
              <input
                placeholder="세로"
                type="tel"
                value={selectedSizes?.sero}
                onChange={(e) => onChangeSize(e, "sero")}
                onKeyDown={handleInput}
              />
            </S.SizeInputBox>
            <S.SizeInputBox>
              <input
                placeholder="높이"
                type="tel"
                value={selectedSizes?.high}
                onChange={(e) => onChangeSize(e, "high")}
                onKeyDown={handleInput}
              />
            </S.SizeInputBox>
            <S.SizeRangeSelect>
              <select
                value={selectedSizes?.erange}
                onChange={(e) => onChangeSize(e, "erange")}
              >
                {sizes.map((el, i) => (
                  <option value={el > 0 ? el : ""} key={i}>
                    ± {el}
                  </option>
                ))}
              </select>
            </S.SizeRangeSelect>
          </S.SizeSearchWrapper>
          <figure
            className="search_icon"
            onClick={() => handleSearch(searchTerm)}
          >
            <img
              src="/assets/imgs/theme/icons/search_icon.png"
              alt="search_icon"
              role="button"
            />
          </figure>
        </S.SearchWrapper>
        <S.SeacrhTags>
          {dummyList?.keywords &&
            Object.values(dummyList.keywords).map((el, i) => (
              <a key={i} onClick={() => handleSearch(el)}>
                #{el}
                {i + 1 !== Object.values(dummyList.keywords).length && ","}
              </a>
            ))}
        </S.SeacrhTags>
      </S.SeacrhSection>
      <S.ScrollSearch
        className={`mr-20 ${scroll ? "d-flex" : "d-none"}`}
        openSearch={openSearch}
        onClick={() => setOpenSearch(true)}
      >
        <S.SeachIcon>
          <i className="fi-rs-search" />
        </S.SeachIcon>
      </S.ScrollSearch>
      {/* 플로팅 검색창 */}
      <S.FloatingSearchWrapper
        open={openSearch}
        $scroll={scroll}
        ref={floatingRef}
      >
        {scroll == true && (
          <S.FloatingScrolledSearchBar>
            <div className="d-flex align-items-center w-100">
              <input
                type="text"
                className="form-control"
                value={searchTerm}
                onKeyDown={handleInput}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <SearchIcon>
                <i
                  className="fi-rs-search"
                  onClick={() => handleSearch(searchTerm)}
                />
              </SearchIcon>
            </div>
          </S.FloatingScrolledSearchBar>
        )}
        <S.FloatingHeader>
          <S.HeaderTop>
            <h6>추천 검색어</h6>
            <h6 onClick={() => setOpenSearch(false)} role="button">
              <i className="fi-rs-x" />
            </h6>
          </S.HeaderTop>
          <S.TagList>
            {dummyList?.keywords &&
              Object.values(dummyList.keywords).map((el, i) => (
                <a
                  onClick={() => {
                    setSizes({
                      garo: "",
                      sero: "",
                      high: "",
                      erange: "",
                    });
                    setOpenSearch(false);
                    router.push(`/search/?search=${el}`);
                  }}
                  role="button"
                  key={i}
                >
                  {el}
                </a>
              ))}
          </S.TagList>
        </S.FloatingHeader>
        <S.FloatingSizeSearch>
          <div className="size_input_wrapper">
            <S.SizeInputBox>
              <input
                placeholder="가로"
                type="tel"
                value={selectedSizes?.garo}
                onChange={(e) => onChangeSize(e, "garo")}
              />
            </S.SizeInputBox>
            <S.SizeInputBox>
              <input
                placeholder="세로"
                type="tel"
                value={selectedSizes?.sero}
                onChange={(e) => onChangeSize(e, "sero")}
              />
            </S.SizeInputBox>
            <S.SizeInputBox>
              <input
                placeholder="높이"
                type="tel"
                value={selectedSizes?.high}
                onChange={(e) => onChangeSize(e, "high")}
              />
            </S.SizeInputBox>
            <S.SizeRangeSelect htmlFor="size-erange">
              <select
                id="size-erange"
                value={selectedSizes?.erange}
                onChange={(e) => onChangeSize(e, "erange")}
              >
                {sizes.map((el, i) => (
                  <option value={el > 0 ? el : ""} key={i}>
                    ± {el}
                  </option>
                ))}
              </select>
            </S.SizeRangeSelect>
          </div>
          <figure
            className="search_icon"
            onClick={() => handleSearch(searchTerm)}
          >
            <img
              src="/assets/imgs/theme/icons/search_icon.png"
              alt="search_icon"
              role="button"
            />
          </figure>
        </S.FloatingSizeSearch>
        <S.FloatingBody>
          <S.RecentSearch>
            <h6>{filteredTags.length > 0 ? "연관 검색어" : "최근 검색어"}</h6>
            <S.RecentList>
              {filteredTags.length > 0 ? (
                filteredTags.map((el, i) => (
                  <S.RecentRow key={i}>
                    <figure>
                      <Image
                        src="/icons/search_icon_gray.png"
                        width={18}
                        height={18}
                        priority
                        alt={"search_icon"}
                      />
                    </figure>
                    <a
                      onClick={() => {
                        setSizes({
                          garo: "",
                          sero: "",
                          high: "",
                          erange: "",
                        });
                        setOpenSearch(false);
                        router.push(`/search/?search=${el}`);
                      }}
                      role="button"
                    >
                      {el}
                    </a>
                  </S.RecentRow>
                ))
              ) : recentList.length > 0 ? (
                recentList.map((el, i) => (
                  <S.RecentRow key={i}>
                    <figure>
                      <Image
                        src="/icons/search_icon_gray.png"
                        width={18}
                        height={18}
                        priority
                        alt={"search_icon"}
                      />
                    </figure>
                    <a
                      onClick={() => {
                        setSizes({
                          garo: "",
                          sero: "",
                          high: "",
                          erange: "",
                        });
                        setOpenSearch(false);
                        router.push(`/search/?search=${el}`);
                      }}
                      role="button"
                    >
                      {el}
                    </a>
                    <span
                      className="delete"
                      onClick={() => deleteRecentSearch(i)}
                    >
                      <i className="fi-rs-x" />
                    </span>
                  </S.RecentRow>
                ))
              ) : (
                <div>최근 검색내역이 없습니다.</div>
              )}
              {filteredTags?.length === 0 && recentList.length > 0 && (
                <S.RecentDeleteAll onClick={() => deleteRecentSearchAll()}>
                  전체삭제
                </S.RecentDeleteAll>
              )}
            </S.RecentList>
          </S.RecentSearch>
          <S.RankSearch>
            <h6>실시간 검색어</h6>
            <S.RankList>
              {dummyList?.ranking &&
                dummyList?.ranking.map((el, i) => (
                  <S.RankRow key={i}>
                    <span className="number">{i + 1}</span>
                    <a
                      onClick={() => {
                        setSizes({
                          garo: "",
                          sero: "",
                          high: "",
                          erange: "",
                        });
                        setOpenSearch(false);
                        router.push(`/search/?search=${el.keyword}`);
                      }}
                      role="button"
                    >
                      <span>{el.keyword}</span>
                    </a>
                    <div className="desc">{el.content}</div>
                  </S.RankRow>
                ))}
            </S.RankList>
          </S.RankSearch>
        </S.FloatingBody>
      </S.FloatingSearchWrapper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dummyList: state.dummyList,
});

const mapDidpatchToProps = { isLoaded, isLoading };

export default connect(mapStateToProps, mapDidpatchToProps)(Search);
