import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect, useState } from "react";
import * as gtag from "../../lib/gtag";

const Seo = ({ title, desc }) => {
  const router = useRouter();

  const [titleTag, setTitleTag] = useState("");
  const changeTitleTag = () => {
    let defaultTitle = "칼렛스토어 :: 미래의 당연함을 만들다";

    if (title) {
      return setTitleTag(`${title} :: 칼렛스토어`);
    } else {
      return setTitleTag(defaultTitle);
    }
  };

  useEffect(() => {
    changeTitleTag();
  }, [title]);

  return (
    <Head>
      <title>{titleTag}</title>
      {/* naver-site-verification START */}
      <meta
        name="naver-site-verification"
        content="73434c46afaf95b1aca89b69bf1bbd9b18d465c8"
      />
      {/* naver-site-verification END */}
      <meta httpEquiv="Cache-Control" content="no-cache" />
      <meta httpEquiv="Expires" content="0" />
      <meta httpEquiv="Pragma" content="no-cache" />
      <meta
        property="og:site_name"
        content="칼렛스토어 :: 미래의 당연함을 만들다"
      />
      <meta
        property="og:title"
        content={titleTag || "칼렛스토어 :: 모든 포장재를 한 곳에서!"}
      />
      <meta property="og:description" content="스마트 패키징 솔루션 플랫폼" />

      <meta property="og:url" content={`https://www.caretstore.co.kr`} />

      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content="https://file.caretbio.com/caretbio/b2c6985b05f7bef24ed2c91965b47455.jpg"
      />
      <meta
        name="description"
        content={
          desc ||
          "모든 종류의 포장재를 하나의 플랫폼에서! 스마트 패키징 솔루션 플랫폼 칼렛스토어. 환경표지인증 및 재활용성 높은 탄소저감 포장재 다수 보유"
        }
      />
      <meta name="author" content="칼렛바이오, 칼렛스토어" />
      <meta
        name="keywords"
        content="칼렛바이오, 칼렛스토어, 스탭포넷제로, Eco, Box, 친환경포장, 지속가능한포장, 지속가능성, sustainability, sustainable, 친환경일회용품, 친환경부자재, 친환경빨대, 친환경봉투, 친환경포장박스, 친환경택배박스, 친환경박스제작, 에코그린박스, 에코박스, 에코프렌즈, 저렴한박스, 날개박스, 친환경박스, 주문제작, 특허박스, 그린박스, 종이박스, 노테이프박스, 접착식박스, 무접착박스, 폴더박스, 그물완충재, 우체국사이즈, A형박스, 납작박스, 긴박스, 우체국사이즈, 우체국박스, 폴더박스, 높이가변형박스, 골판지패드, 택배박스, 농산물박스, 완충패드, 종이난좌, 포도완충재, 계란완충재, 멜론완충재, 수박완충재, 공기주입기, 바이오비닐봉투, 택배봉투, 스텝포넷제로, 속비닐, 링봉투, 손잡이봉투, 위생백, 지퍼백, 종이테이프, 종이완충재, 벌집완충재, 흰색속지, 완충재거치대, 크라프트완충재, 화지, 전용기계, 대량구매, 생분해에어완충재, 에어완충재, 포장기계, 보냉보온포장재, 칼렛에어쿨팩, 칼렛에어파우치, 공기주입기, 보냉팩, 워터아이스팩, 아이스팩반제품, 종이아이스팩, 소량구매, 대량구매, 펄프용기, 크라프트용기, 플라스틱용기, 실링용기, 국물요리, 치킨샐러드, 도시락, 햄버거, 초밥용기, 접시, 소스통, 일회용나무식기, 일회용생분해식기, 위생장갑, 아이스컵, 아이스컵뚜껑, 종이컵, 종이컵뚜껑, 생분해빨대, 종이빨대, 대나무빨대, 기타용품, 스트레치필름, 반려동물용품, 배변봉투, 식기"
      />
      <link rel="icon" href="/favicon.svg" />
      <link rel="manifest" href="/manifest.json" />
      <meta name="theme-color" content="#3bb77e" />
      {/* google-site-verification Start */}
      <meta
        name="google-site-verification"
        content="Rra2K_-5diyQmqHmSncqz4EJUQ3aXQLOgJ95bsVqrDY"
      />
      {/* google-site-verification END */}
    </Head>
  );
};

export default Seo;
