import Link from "next/link";

const Breadcrumb = ({ parent, sub, subTitle, subChild, noBreadcrumb }) => {
  return (
    <>
      <div className={`page-header breadcrumb-wrap ${noBreadcrumb}`}>
        <div className="container">
          <div className="breadcrumb">
            <Link href="/">
              <a>{parent}</a>
            </Link>
            {sub ? (
              <>
                <span /> {sub}
              </>
            ) : (
              <></>
            )}
            {subTitle ? (
              <>
                <span /> {subTitle}
              </>
            ) : (
              <></>
            )}
            {subChild ? (
              <>
                <span /> {subChild}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Breadcrumb;
