import { useEffect, useState } from "react";
import { EventPagesAPI } from "../../api/apis";
import { getTimeStamp } from "../util/getDate";
import styled from "styled-components";

const BadgeWrapper = styled.div`
  position: absolute;
  top: ${(props) => (props.$scroll ? "-25px" : "-30px")};
  left: 50%;
  transform: translateX(-50%);
  font-size: ${(props) => (props.$scroll ? "12px" : "14px")};

  .speech-bubble {
    display: inline-block;
    position: relative;
    background-color: #e4f5d4;
    color: #3bb77e;
    padding: 5px 10px;
    border-radius: 20px;
    line-height: 1;

    &::after {
      content: "";
      position: absolute;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);
      border-width: 8px;
      border-style: solid;
      border-color: #e4f5d4 transparent transparent transparent;
    }
  }
`;

const ProceedingMenuBadge = ({ siteInfo }) => {
  const [detailData, setDetailData] = useState({});
  const [open, setOpen] = useState("loading");
  const [scroll, setScroll] = useState(false);

  // check open
  const checkOpen = (data) => {
    if (!data.sdate) {
      return "loading";
    }
    if (getTimeStamp() < new Date(data.sdate).getTime()) {
      return "before";
    } else if (getTimeStamp() > new Date(data.edate).getTime()) {
      return "after";
    } else {
      return "proceeding";
    }
  };

  const fetchDetail = async (code) => {
    const body = {
      modes: "ViewSet",
      code: code,
    };
    const res = await EventPagesAPI(body);
    if (res?.Code === "0000") {
      setDetailData({ ...res.Data?.Row, info: res?.Data?.Info });
      setOpen(checkOpen({ ...res.Data?.Row, info: res?.Data?.Info }));
    }
  };

  useEffect(() => {
    if (siteInfo.Purchase) {
      fetchDetail(siteInfo.Purchase);
    }
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY >= 100;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    });
  }, [siteInfo]);

  return (
    <>
      {open === "proceeding" && (
        <BadgeWrapper $scroll={scroll}>
          <div className="speech-bubble">진행중</div>
        </BadgeWrapper>
      )}
    </>
  );
};

export default ProceedingMenuBadge;
