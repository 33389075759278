import { useState } from "react";
import { priceComma } from "../../../common/splicePrice";
import * as U from "../user.styles";
import { useEffect } from "react";
import { insertBaskets } from "../../../api/baskets";
import { useRouter } from "next/router";
import { getCookie } from "../../../common/auth";
import { successAlert } from "../../../common/alert";

const UserCart = (props) => {
  const {
    activeTab,
    basketItems,
    clearBasketsAction,
    modifyConfirmModal,
    openConfirmModal,
    closeConfirmModal,
    deleteBasketsAction,
    fetchBasketsAction,
    cartItems,
    clearCart,
    setActievTab,
    decreaseCnt,
    increaseCnt,
  } = props;
  const router = useRouter();
  const [confirmItems, setConfirmItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [cartLoading, setCartLoading] = useState(false);
  const [cartProducts, setCartProducts] = useState([]);
  const [token, setToken] = useState();

  // 전체 선택
  const selectAllItems = (e) => {
    if (e.target.checked) {
      const tempArr = new Array(cartProducts.length).fill(true);
      setSelectedItem(tempArr);
      setConfirmItems(cartProducts);
    } else {
      const tempArr2 = new Array(cartProducts.length).fill(false);
      setSelectedItem(tempArr2);
      setConfirmItems([]);
    }
  };

  // 전체삭제
  const deleteAll = () => {
    const content = {
      title: "전부 삭제하시겠습니까?",
      actionBtnLabel: "확인",
      actionBtnValid: true,
      cancelBtnLabel: "취소",
      onAction: () => {
        if (getCookie("token")) {
          clearBasketsAction();
        } else {
          clearCart();
          successAlert("장바구니 전체 삭제 완료");
        }
        setConfirmItems([]);
        closeConfirmModal();
      },
    };
    modifyConfirmModal(content);
    openConfirmModal();
  };

  // 선택
  const selectItem = (e, el, i) => {
    const tempArr = [...selectedItem];
    tempArr[i] = e.target.checked;
    setSelectedItem(tempArr);
    if (e.target.checked) {
      const tempArr2 = [...confirmItems];
      tempArr2.push(el);
      setConfirmItems(tempArr2);
    } else {
      const tempArr2 = [...confirmItems];
      const index = tempArr2.findIndex((product) => product.pcode === el.pcode);
      tempArr2.splice(index, 1);
      setConfirmItems(tempArr2);
    }
  };

  // 선택 삭제
  const handleDelete = (item, i) => {
    const content = {
      title: "삭제하시겠습니까?",
      actionBtnLabel: "확인",
      actionBtnValid: true,
      cancelBtnLabel: "취소",
      onAction: () => {
        deleteBasketsAction(item);
        confirmItems.splice(i, 1);
        setConfirmItems([...confirmItems]);
        selectedItem.splice(i, 1);
        setSelectedItem([...selectedItem]);
        closeConfirmModal();
      },
    };
    modifyConfirmModal(content);
    openConfirmModal();
  };

  // 회원 장바구니 갯수 증가
  const increaseBasketProduct = async (item) => {
    const i = basketItems.findIndex((el) => el.pcode === item.pcode);
    basketItems[i].cnt = Number(basketItems[i].cnt) + 1;
    const body = {
      modes: "InsertSet",
      items: JSON.stringify([{ pcode: item.pcode, cnt: basketItems[i].cnt }]),
    };
    const data = await insertBaskets(body);
    if (data?.code === "0000") {
      setConfirmItems(data?.data);
    }
  };

  // 회원 장바구니 갯수 감소
  const decreaseBasketProduct = async (item) => {
    const i = basketItems.findIndex((el) => el.pcode === item.pcode);
    if (basketItems[i].cnt > 1)
      basketItems[i].cnt = Number(basketItems[i].cnt) - 1;

    const body = {
      modes: "InsertSet",
      items: JSON.stringify([{ pcode: item.pcode, cnt: basketItems[i].cnt }]),
    };
    const data = await insertBaskets(body);

    if (data?.code === "0000") {
      setConfirmItems(data?.data);
    }
  };

  // 결제하기
  const onClickCheckout = async () => {
    if (basketItems?.length < 1) {
      return failAlert("장바구니에 상품이 없습니다");
    }

    if (confirmItems.filter((el) => el != undefined)?.length < 1) {
      return failAlert("선택된 상품이 없습니다");
    }

    sessionStorage.setItem(
      "cartOrder",
      JSON.stringify(confirmItems.filter((el) => el != undefined))
    );
    setActievTab(undefined);
    router.push({
      pathname: "/checkout",
      query: {
        type: "cart",
      },
    });
  };

  // 전체 금액
  const price = () => {
    let price = 0;
    confirmItems?.forEach((item) => (price += item.discount_price * item.cnt));

    return price;
  };
  // 할인,할증 없는 배송료
  const nonCheckDelPrice = () => {
    let delPrice = 0;

    confirmItems?.forEach(
      (item) =>
        (delPrice +=
          (item.delivery_type === "무료" || item.delivery === "무료"
            ? 0
            : item.delivery_price) * (item.bundle === "Y" ? item.cnt : 1))
    );
    return delPrice;
  };
  // 할인,할증 포함 배송료
  const deliveryPrice = () => {
    let delPrice = 0;
    let baesong_temp = [];

    confirmItems?.forEach((temp_item) => {
      let delivery_price_temp = 0;
      if (temp_item.delivery_type === "C" || temp_item.delivery === "C")
        return 0;

      delivery_price_temp = Number(temp_item.delivery_price);

      let delivery_price = 0;
      if (
        temp_item.delivery_type === "A" ||
        temp_item.delivery_type === "F" ||
        temp_item.delivery === "A" ||
        temp_item.delivery === "F"
      ) {
        if (temp_item.bundle === "Y") {
          delivery_price = delivery_price_temp * Number(temp_item.cnt);
        } else if (temp_item.bundle === "N") {
          if (baesong_temp.includes(temp_item.supplier)) {
            delivery_price = 0;
          } else {
            delivery_price = delivery_price_temp;
            baesong_temp.push(temp_item.supplier);
          }
        }
      } else {
        delivery_price = delivery_price_temp;
      }

      delPrice += delivery_price;
    });
    return delPrice;
  };

  // 토큰 유무 판별
  const checkItems = async () => {
    setCartLoading(true);
    if (token) {
      if (!basketItems) return;
      setCartProducts(basketItems);
      if (selectedItem.length > 0 && basketItems.length > selectedItem.length) {
        const newItems = new Array(
          basketItems.length - selectedItem.length
        ).fill(true);
        setSelectedItem([...newItems, ...selectedItem]);
        setConfirmItems([basketItems[0], ...confirmItems]);
        return;
      } else {
        setConfirmItems(basketItems);
        setSelectedItem(
          new Array(basketItems ? basketItems.length : 1).fill(true)
        );
      }
      setCartLoading(false);
    } else {
      if (!cartItems) return;
      setCartProducts(cartItems);
      if (selectedItem.length > 0 && cartItems.length > selectedItem.length) {
        const newItems = new Array(cartItems.length - selectedItem.length).fill(
          true
        );
        setSelectedItem([...newItems, ...selectedItem]);
        setConfirmItems([cartItems[0], ...confirmItems]);
        return;
      } else {
        setConfirmItems(cartItems);
        setSelectedItem(new Array(cartItems ? cartItems.length : 1).fill(true));
      }
      setCartLoading(false);
    }
  };
  // 회원 여부 체크
  const checkUser = async () => {
    const token = await getCookie("token");
    setToken(token);
  };
  useEffect(() => {
    checkUser();
  }, []);

  useEffect(() => {
    checkItems();
  }, [token, cartItems, basketItems]);

  return (
    <>
      {cartProducts && cartProducts.length > 0 ? (
        <U.InnerListWrapper>
          <div className="mb-2 d-flex justify-content-between align-items-center">
            <div className="custome-checkbox">
              <input
                type="checkbox"
                className="form-check-input"
                id="checkAllCart"
                onChange={selectAllItems}
                checked={selectedItem.includes(false) ? false : true}
              />
              <label
                className="form-check-label d-flex align-items-center text-dark"
                htmlFor="checkAllCart"
              >
                전체 선택
              </label>
            </div>
            <U.AlldeleteBtn onClick={() => deleteAll()}>
              전체삭제
            </U.AlldeleteBtn>
          </div>
          {cartProducts.map((product, i) => (
            <div key={i} className="product_box">
              <div className="top">
                <div className="custome-checkbox">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={`checkBoxCart${i}`}
                    onChange={(e) => selectItem(e, product, i)}
                    checked={selectedItem[i] ? true : false}
                  />
                  <label
                    className="form-check-label d-flex"
                    htmlFor={`checkBoxCart${i}`}
                  >
                    <h6 className="pname">{product.pname}</h6>
                  </label>
                </div>
                {activeTab === "cart" && (
                  <a
                    className="deleteBtn"
                    onClick={() => handleDelete(product)}
                  >
                    <i className="fi-rs-trash" />
                  </a>
                )}
              </div>
              <div className="d-flex">
                <div
                  className="main_image_wrapper"
                  onClick={() => router.push(`/products/${product.pcode}`)}
                >
                  <img
                    src={product.main_image}
                    alt={product.pname}
                    role="button"
                    className="main_image"
                  />
                  <div className="hover_cover">상세보기</div>
                </div>
                <div className="d-flex flex-column justify-content-between ms-2 py-1">
                  <div className="cnt_box">
                    <span className="icon">
                      <i
                        className="fi-rs-minus-small"
                        onClick={(e) =>
                          token
                            ? decreaseBasketProduct(product)
                            : decreaseCnt(product.pcode)
                        }
                      />
                    </span>
                    <span className="cnt">{product.cnt}</span>
                    <span className="icon">
                      <i
                        className="fi-rs-plus-small"
                        onClick={(e) =>
                          token
                            ? increaseBasketProduct(product)
                            : increaseCnt(product.pcode)
                        }
                      />
                    </span>
                  </div>
                  <div className="price">
                    {priceComma(product.discount_price)}원
                  </div>
                </div>
              </div>
            </div>
          ))}
        </U.InnerListWrapper>
      ) : (
        <U.InnerEmptyWrapper>
          <span className="empty_icon">
            <i className="fi-rs-box-open" />
          </span>
          <p>장바구니에 상품이 없습니다.</p>
        </U.InnerEmptyWrapper>
      )}
      <U.CartBtnWrapper>
        <ul>
          <li>
            <span>
              총 <strong>{confirmItems?.length}</strong>건
            </span>
          </li>
          <li>
            <span>총 상품금액</span>
            <span>{priceComma(price()) + "원"}</span>
          </li>
          <li>
            <span>총 배송비</span>
            <span>{"+" + priceComma(nonCheckDelPrice()) + "원"}</span>
          </li>
          {deliveryPrice() - nonCheckDelPrice() !== 0 && (
            <li
              className={
                deliveryPrice() - nonCheckDelPrice() < 0 ? "text-danger" : ""
              }
            >
              <span>
                {deliveryPrice() - nonCheckDelPrice() < 0 && "묶음 배송 할인"}
              </span>
              <span>{priceComma(deliveryPrice() - nonCheckDelPrice())}원</span>
            </li>
          )}
          <li>
            <span>예상 결제금액</span>
            <span>{priceComma(price() + deliveryPrice())}원</span>
          </li>
        </ul>
        <span className="info">
          * 최종 결제 금액은 도서산간 할증, 쿠폰 사용 등으로 인해 변동될 수
          있습니다.
        </span>
      </U.CartBtnWrapper>
      <button className="btn reorder" onClick={() => onClickCheckout()}>
        선택 상품 주문
      </button>
    </>
  );
};

export default UserCart;
