import styled from "styled-components";

export const UserFloatingWrapper = styled.div`
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 500;
  display: flex;
  height: ${(props) =>
    props.$scroll ? "calc(100vh - 73px)" : "calc(100vh - 235px )"};
  transform: ${(props) =>
    props.openInner ? "translate(0px)" : "translate(340px)"};
  transition: all 0.3s ease-in-out;
  color: #1b1b1b;
  .custome-checkbox .form-check-label::before {
    min-width: 17px;
  }
  & i {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 575px) {
    display: none;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .icon {
    background-color: #fff;
    font-size: 20px;
    margin-bottom: 5px;
    border-radius: 10px;
    background-color: #fff;
    width: 59px;
    height: 54px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: -3px 3px 7px 0px rgba(0, 0, 0, 0.1);
  }
  .active {
    background-color: #3bb77e;
    color: #fff;
  }
`;

export const InnerWrapper = styled.div`
  width: 340px;
  min-height: 700px;
  height: 100%;
  background-color: #fff;
  border: 1px solid #ececec;
  display: flex;
  flex-direction: column;
`;

export const InnerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 15px;
  border-bottom: 1px solid #ececec;
  font-size: 18px;
`;

export const InnerBody = styled.div`
  position: relative;
  padding: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
`;

export const InnerTabWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ececec;
  .tab {
    width: 50%;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    :not(:last-child) {
      border-right: 1px solid #ececec;
    }
    & i {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .active {
    background-color: #3bb77e;
    color: #fff;
  }
`;

export const AlldeleteBtn = styled.span`
  cursor: pointer;
  color: #1b1b1b;
  width: fit-content;
  line-height: 1.2;
  border-bottom: 2px solid #1b1b1b;
  :hover {
    color: #3bb77e;
    border-color: #3bb77e;
  }
`;

export const InnerListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 15px;
  overflow-y: auto;
  .product_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px 0px;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      overflow-y: hidden;

      .pname {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre-wrap;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 14px;
        font-weight: 300;
        cursor: pointer;
      }
      & i {
        margin-left: 25px;
        cursor: pointer;
      }
    }
    .main_image_wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .main_image {
        width: 80px;
        height: auto;
      }
      .hover_cover {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background: #fff;
        transition: all 0.1s ease-in;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      :hover {
        .hover_cover {
          opacity: 0.7;
        }
      }
    }

    .cnt_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 300;
      border: 2px solid #e5e5e5;
      background: #fff;
      padding: 5px;

      .cnt {
        padding: 0px 10px;
      }
      .icon {
        padding: 0px 5px;
        cursor: pointer;
        :hover {
          color: #3bb77e;
        }
      }
    }

    .price {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .deleteBtn {
    color: #1b1b1b;
    :hover {
      color: #3bb77e;
    }
  }
`;

export const InnerEmptyWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 25px;
  color: #c4c4c4;
  margin: auto 0;
  .empty_icon {
    overflow: hidden;
    font-size: 150px;
    line-height: normal;
    & i {
      margin-bottom: 20px;
    }
  }
`;

export const ReOrderBtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 25px 16px;
  margin-top: auto;

  .result {
    border-top: 1px solid #ececec;
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
  }
  .cnt {
    font-size: 16px;
    & strong {
      color: #3bb77e;
    }
  }
  .price {
    color: #3bb77e;
    font-size: 20px;
    font-weight: 500;
  }
  & button {
    margin-top: 8px;
    height: 59px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .reorder {
    :hover {
      background-color: #3bb77e;
    }
  }
  .cart {
    background-color: #fff;
    border-color: #3bb77e;
    color: #3bb77e;
  }
`;

export const CartBtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-top: 1px solid #e4e4e4;
  background-color: #fff;
  padding: 10px 15px 0px 15px;
  margin-bottom: 10px;
  margin-top: auto;
  color: #1b1b1b;

  & strong {
    color: #3bb77e;
  }

  & li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 1rem;
    :last-of-type {
      font-weight: 500;
      color: #3bb77e;
      margin-bottom: 0px;
      margin-top: 15px;
    }
  }
  .info {
    line-height: 1.2;
    margin-top: 10px;
    font-size: 0.8rem;
    word-break: keep-all;
  }
`;

export const InnerFooter = styled.div`
  border-top: 1px solid #ececec;
  padding: 10px;
  display: flex;
  justify-content: center;
`;

export const MobileFooter = styled.div`
  z-index: 50;
  position: fixed;
  bottom: 0;
  width: 100vw;
  background-color: #fff;
  display: none;
  justify-content: space-between;
  border-top: 1px solid #ececec;
  @media screen and (max-width: 575px) {
    display: flex;
  }
  .active {
    color: #3bb77e;
  }
`;

export const MobileFooterTab = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 3px;
  & i {
    font-size: 20px;
  }
  & span {
    font-size: 12px;
  }
  position: relative;
  .search {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -5px;
    z-index: 99;
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
    width: 54px;
    height: 54px;
    & img {
      width: 100%;
    }
  }
`;

// userview
export const ViewListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ViewListBox = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ececec;
  padding-bottom: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  .main_img_wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .main_image {
      min-width: 80px;
      max-width: 80px;
      height: auto;
    }
    .hover_cover {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      background: #fff;
      transition: all 0.1s ease-in;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    :hover {
      .hover_cover {
        opacity: 0.7;
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    padding: 5px 10px;

    & h6 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-wrap;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 10px;
    }
    & p {
      font-size: 14px;
      font-weight: 300;
      color: #1b1b1b !important;
    }
  }
`;
