import { useState } from "react";
import * as U from "../user.styles";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { priceComma } from "../../../common/splicePrice";

const UserView = (props) => {
  const { viewList } = props;
  const router = useRouter();

  return (
    <>
      <U.ViewListWrapper>
        {viewList &&
          viewList.length > 0 &&
          viewList.map((product, i) => (
            <U.ViewListBox
              key={i}
              onClick={() => router.push(`/products/${product.pcode}`)}
            >
              <div className="main_img_wrapper">
                <img
                  src={product.main_image}
                  alt={product.pname}
                  className="main_image"
                />
                <span className="hover_cover">상세보기</span>
              </div>
              <div className="info">
                <h6>{product.pname}</h6>
                <p>{priceComma(product.discount_price)}원</p>
              </div>
            </U.ViewListBox>
          ))}
      </U.ViewListWrapper>
    </>
  );
};
export default UserView;
