import { varListDummy } from "../../api/dummy";
import * as Types from "../constants/actionTypes";

export const fetchDummyList = () => async (dispatch) => {
  try {
    const res = await varListDummy();

    dispatch({
      type: Types.FETCH_DUMMY,
      payload: { dummyList: res.Data },
    });
  } catch (error) {
    console.error(error);
  }
};
