import * as F from "../layout/Footer.styles";

const FooterFloating = (props) => {
  const { onClickTopButton, scrollY } = props;
  return (
    <>
      {scrollY && (
        <>
          <F.ScrollTopBtn onClick={onClickTopButton}>
            <img src="/assets/imgs/theme/icons/toTopIcon_150x150.png" />
          </F.ScrollTopBtn>
        </>
      )}
    </>
  );
};
export default FooterFloating;
