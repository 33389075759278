import http from "./constants/http";
import resources from "./constants/resources";

export const varListDummy = async () => {
  const body = {
    modes: "VarList",
  };
  const res = await http(resources.DUMMY).post(body);

  if (res.status === 200) {
    return res.data;
  } else {
    return res;
  }
};

export const postDefaultArray = async (body) => {
  const res = await http(resources.DUMMY).post(body);

  if (res.status === 200) {
    return res.data;
  } else {
    return res;
  }
};
