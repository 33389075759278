export const logoutConfirm = (logoutConfirmHandler) => {
  const content = {
    onAction: logoutConfirmHandler,
    title: "로그아웃 하시겠습니까?",
    actionBtnLabel: "확인",
    actionBtnValid: true,
    cancelBtnLabel: "취소",
  };

  return content;
};

export const uidConfirm = (message) => () => {
  const content = {
    title: message,
    actionBtnValid: false,
    cancelBtnLabel: "확인",
  };

  return content;
};

export const cancelOrderConfirm = (handleSubmit) => {
  const content = {
    onAction: handleSubmit,
    title: "정말 주문을 취소하시겠습니까?",
    actionBtnLabel: "확인",
    actionBtnValid: true,
    cancelBtnLabel: "취소",
  };
  return content;
};

export const changeConfirmOption = {
  title: "정말 변경 하시겠습니까?",
  actionBtnLabel: "확인",
  actionBtnValid: true,
  cancelBtnLabel: "취소",
};

export const modifyConfirmOption = {
  title: "정말 수정 하시겠습니까?",
  actionBtnLabel: "확인",
  actionBtnValid: true,
  cancelBtnLabel: "취소",
};

export const leaveConfirmOption = {
  title: "정말 탈퇴 하시겠습니까?",
  actionBtnLabel: "확인",
  actionBtnValid: true,
  cancelBtnLabel: "취소",
};

export const submitConfirmOption = {
  title: "정말 등록 하시겠습니까?",
  actionBtnLabel: "확인",
  actionBtnValid: true,
  cancelBtnLabel: "취소",
};

export const deleteConfirmOption = {
  title: "정말 삭제 하시겠습니까?",
  actionBtnLabel: "확인",
  actionBtnValid: true,
  cancelBtnLabel: "취소",
};

export const changeNotiConfirmOption = {
  title: "정말 알림 상태를 변경하시겠습니까?",
  actionBtnLabel: "확인",
  actionBtnValid: true,
  cancelBtnLabel: "취소",
};
