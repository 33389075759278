import http from "./constants/http";
import resources from "./constants/resources";

export const createOrder = async (body) => {
  const res = await http(resources.ORDER).post(body);
  return res;
};

export const makeOrder = async (body) => {
  const res = await http(resources.ORDER).post(body);

  const resData = {
    code: res?.data?.Code,
    data: res?.data?.Data,
    msg: res?.data?.Data?.Msg,
  };

  return resData;
};

// 결제전 상품 가격 설정
export const createTempOrder = async (body) => {
  const res = await http(resources.ORDER).post(body);
  const resData = res?.data;

  return resData;
};

export const CarbonInsert = async (body) => {
  const res = await http(resources.ORDER).post(body);

  if (res.status === 200) {
    return res.data;
  } else {
    return res;
  }
};

// 마이페이지 order api
export const postOrder = async (body) => {
  const res = await http(resources.ORDER).post(body);

  if (res.status === 200) {
    return res;
  } else {
    res;
  }
};

// 주문번호로 조회
export const GuestOrderFind = async (body) => {
  const res = await http(resources.ORDER).post(body);
  if (res.status === 200) {
    return res.data;
  } else {
    return res;
  }
};

// order common
export const StoreOrderApi = async (body) => {
  const res = await http(resources.ORDER).post(body);
  if (res.status === 200) {
    return res.data;
  } else {
    return res;
  }
};
