import styled from "styled-components";

const ToggleWrapper = styled.div`
  width: ${(props) => props.width || "70px"};
  background-color: ${(props) => props.bgColor || "#c4c4c4"};
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  padding: 2px;
  height: ${(props) => props.height || "32px"};
  position: relative;
  margin-left: ${(props) => props.mlAuto || "0"};

  .dialog-button {
    font-size: ${(props) => props.fontSize || "12px"};
    line-height: 16px;
    font-weight: bold;
    cursor: pointer;
    background-color: ${(props) => props.btnBgColor || "#002b49"};
    color: white;
    padding: ${(props) => props.btnPadding || "8px 12px"};
    border-radius: 18px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /* min-width: 46px; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.btnWidth || "38px"};
    min-width: unset;
    border-radius: 3px;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    left: ${(props) => props.btnLeft};
    transition: all 0.3s ease;
  }

  .disabled {
    background-color: ${(props) => props.disabledColor || "#707070"};
    left: ${(props) => props.disabledLeft};
  }
`;

export const ToggleButton = (props) => {
  return (
    <ToggleWrapper
      width={props.width}
      height={props.height}
      fontSize={props.fontSize}
      bgColor={props.bgColor}
      btnWidth={props.btnWidth}
      btnBgColor={props.btnBgColor}
      btnPadding={props.btnPadding}
      btnLeft={props.btnLeft}
      disabledColor={props.disabledColor}
      disabledLeft={props.disabledLeft}
      mlAuto={props.mlAuto}
      onClick={() => props.onClickToggleBtn()}
    >
      <div
        className={`dialog-button ${props.isToggleActive ? "" : "disabled"}`}
      >
        {props.isToggleActive ? "On" : "Off"}
      </div>
    </ToggleWrapper>
  );
};
