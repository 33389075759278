import * as U from "./user.styles";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useState } from "react";
import { StoreOrderApi, postOrder } from "../../api/order";
import { failAlert } from "../../common/alert";
import { getCookie } from "../../common/auth";
import { MobileSocialIconWrapper } from "../layout/Footer.styles";
import Link from "next/link";
import { priceComma } from "../../common/splicePrice";
import { insertBaskets } from "../../api/baskets";
import UserReOrder from "./user/UserReOrder";
import UserCart from "./user/UserCart";
import UserView from "./user/UserView";

const UserFloating = (props) => {
  const {
    scroll,
    userInfo,
    basketItems,
    clearBasketsAction,
    modifyConfirmModal,
    openConfirmModal,
    closeConfirmModal,
    deleteBasketsAction,
    fetchBasketsAction,
    insertBasketsAction,
    vp,
    cartItems,
    clearCart,
    decreaseCnt,
    increaseCnt,
    onClickSearch,
    toggleClick,
  } = props;
  const router = useRouter();
  const [activeTab, setActievTab] = useState("");
  const [viewList, setViewList] = useState([]);

  const handleOpen = (tab) => {
    if (activeTab === tab) {
      setActievTab(undefined);
      return;
    }
    setActievTab(tab);
  };

  useEffect(() => {
    if (!router.isReady) return;
    if (vp.vp && vp.vp.length > 0) {
      setViewList(vp.vp);
    } else {
      setViewList(JSON.parse(sessionStorage.getItem("vp")));
    }
  }, [router.isReady, router.query, vp]);

  return (
    <>
      <U.UserFloatingWrapper openInner={activeTab} $scroll={scroll}>
        <U.IconsWrapper>
          <div
            className={`icon ${activeTab === "reorder" && "active"}`}
            onClick={() => handleOpen("reorder")}
          >
            <i className="fi-rs-time-past" />
          </div>
          <div
            className={`icon ${activeTab === "cart" && "active"}`}
            onClick={() => handleOpen("cart")}
          >
            <i className="fi-rs-shopping-cart" />
          </div>
          <div
            className={`icon ${activeTab === "view" && "active"}`}
            onClick={() => handleOpen("view")}
          >
            {viewList && viewList.length > 0 ? (
              <img
                src={viewList[0].main_image}
                alt={viewList[0].pname}
                style={{
                  width: "40px",
                }}
              />
            ) : (
              <i className="fi-rs-square-plus" />
            )}
          </div>
        </U.IconsWrapper>
        <U.InnerWrapper openInner={activeTab} scroll={scroll}>
          <U.InnerHeader>
            <div>
              {userInfo?.Member?.name ? (
                <a href="/mypage/order">{userInfo?.Member?.name} 님</a>
              ) : (
                <a href="/login">로그인 해주세요.</a>
              )}
            </div>
            <a role="button" onClick={() => handleOpen()}>
              <i className="fi-rs-cross" />
            </a>
          </U.InnerHeader>
          {activeTab !== "view" && (
            <U.InnerTabWrapper>
              <span
                className={`tab ${activeTab === "reorder" && "active"}`}
                onClick={() => handleOpen("reorder")}
              >
                <i className="fi-rs-time-past me-1" />
                재구매
              </span>
              <span
                className={`tab ${activeTab === "cart" && "active"}`}
                onClick={() => handleOpen("cart")}
              >
                <i className="fi-rs-shopping-cart me-1" />
                장바구니
              </span>
            </U.InnerTabWrapper>
          )}
          <U.InnerBody>
            {activeTab === "reorder" && (
              <UserReOrder
                activeTab={activeTab}
                setActievTab={setActievTab}
                insertBasketsAction={insertBasketsAction}
                fetchBasketsAction={fetchBasketsAction}
              />
            )}
            {activeTab === "cart" && (
              <UserCart
                activeTab={activeTab}
                setActievTab={setActievTab}
                basketItems={basketItems}
                clearBasketsAction={clearBasketsAction}
                modifyConfirmModal={modifyConfirmModal}
                openConfirmModal={openConfirmModal}
                closeConfirmModal={closeConfirmModal}
                deleteBasketsAction={deleteBasketsAction}
                fetchBasketsAction={fetchBasketsAction}
                insertBasketsAction={insertBasketsAction}
                cartItems={cartItems}
                clearCart={clearCart}
                decreaseCnt={decreaseCnt}
                increaseCnt={increaseCnt}
              />
            )}

            {activeTab === "view" && <UserView viewList={viewList} />}
          </U.InnerBody>
          <U.InnerFooter>
            <MobileSocialIconWrapper>
              <Link href="https://pf.kakao.com/_MCxnhxj" target="_blank">
                <a target="_blank">
                  <img src="/assets/imgs/theme/icons/kakao-talk.png" alt="" />
                </a>
              </Link>
              <Link href="https://www.facebook.com/caretstore" target="_blank">
                <a target="_blank">
                  <img
                    src="/assets/imgs/theme/icons/icon-facebook-white.svg"
                    alt=""
                  />
                </a>
              </Link>
              <Link
                href="https://www.instagram.com/caretstore/"
                target="_blank"
              >
                <a target="_blank">
                  <img
                    src="/assets/imgs/theme/icons/icon-instagram-white.svg"
                    alt=""
                  />
                </a>
              </Link>
              <Link href="https://talk.naver.com/ct/w4kznv">
                <a target="_blank">
                  <img
                    src="/assets/imgs/theme/icons/icon-naver-talk.png"
                    alt=""
                  />
                </a>
              </Link>
              <Link href="https://www.youtube.com/channel/UC1MTYtvC363yWAB4uf8CBhQ">
                <a target="_blank">
                  <img
                    src="/assets/imgs/theme/icons/icon-youtube-white.svg"
                    alt=""
                  />
                </a>
              </Link>
              <Link href="https://blog.naver.com/caretstore" target="_blank">
                <a target="_blank">
                  <img
                    src="https://cdn.imweb.me/upload/S20200506960caff1c89c9/522104c187709.png"
                    alt=""
                  />
                </a>
              </Link>
            </MobileSocialIconWrapper>
          </U.InnerFooter>
        </U.InnerWrapper>
      </U.UserFloatingWrapper>
      {/* 모바일 하단 메뉴 */}
      <U.MobileFooter>
        <U.MobileFooterTab
          className={`${router.pathname === "/" && "active"}`}
          onClick={() => router.push("/")}
        >
          <i className="fi-rs-home" />
          <span>홈</span>
        </U.MobileFooterTab>
        <U.MobileFooterTab onClick={() => toggleClick()}>
          <i className="fi-rs-burger-menu" />
          <span>메뉴</span>
        </U.MobileFooterTab>
        <U.MobileFooterTab>
          <figure className="search" onClick={() => onClickSearch()}>
            <img
              src="/assets/imgs/theme/icons/search_icon.png"
              alt="search_icon"
            />
          </figure>
        </U.MobileFooterTab>
        <U.MobileFooterTab
          className={`${router.pathname === "/reorder" && "active"}`}
          onClick={() => router.push("/reorder")}
        >
          <i className="fi-rs-time-past" />
          <span>재구매</span>
        </U.MobileFooterTab>
        <U.MobileFooterTab
          className={`${router.pathname.includes("/mypage") && "active"}`}
          onClick={() => router.push("/mypage/order")}
        >
          <i className="fi-rs-user" />
          <span>마이페이지</span>
        </U.MobileFooterTab>
      </U.MobileFooter>
    </>
  );
};
export default UserFloating;
